import { history } from 'umi';
import {deleteCookie} from "@/utils/cookie";
import {setIsLogin} from '@/states/store'
import {wsStore} from '@/states/ws'
import { getQuery } from '.';


export const logOut=()=>{
    deleteCookie('userId')
    deleteCookie('token')
    // state.close();
    setIsLogin(false)
    let logOutUrl='/'
    if(getQuery()?.shareCode){
      logOutUrl='/?shareCode='+getQuery()?.shareCode
    }
    if(getQuery()?.code){
      logOutUrl='/?code='+getQuery()?.code
    }
    history.push({
      pathname:logOutUrl
    })
    wsStore.onPortLogout()
    location.reload()
}

export const logoutReload=()=>{
  setIsLogin(false)
  let logOutUrl='/'
  if(getQuery()?.shareCode){
    logOutUrl='/?shareCode='+getQuery()?.shareCode
  }
  if(getQuery()?.code){
    logOutUrl='/?code='+getQuery()?.code
  }
  history.push({
    pathname:logOutUrl
  })
  location.reload()
}