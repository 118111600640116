import { getCookie } from '@/utils/cookie';
import request from '@/utils/request';

export const getTagList = (data: any) => {
  return request({
    url: '/waic/core/common/materialTagList',
    data: data,
    method: 'GET',
  });
};

export const getTagListV2 = (data: any) => {
  return request({
    url: '/waic/core/common/materialTagListV2',
    data: data,
    method: 'GET',
  });
};

export const getMaterialListByPage = (data:any) => {
  return request({
    url: '/waic/core/common/materialListByPage',
    method: 'POST',
    data,
  })
}

export const getMaterialList = (data: any) => {
  return request({
    url: '/waic/core/common/materialList',
    data: data,
    method: 'GET',
  });
};

export const getActivityData = (activityId: number) => {
  return request({
    url: '/waic/account/activity/join',
    method: 'POST',
    data: {
      data: {
        activityId
      }
    }
  })
}


export const getActivityList = () => {
  return request({
    url: '/waic/account/activity/list',
    method: 'POST'
  })
}

// 获取通用校验，例如首次调用优惠券、首次用户引导等
export const getCommonCheck = (data:any) => {
  return request({
    url: '/waic/account/periodic/checkin',
    method: 'POST',
    data,
  })
}

/**
 * 登录
 */
 export const autoLogin: Fetch<ILoginReq> = (data) => {
  let channelSource = getCookie('channel_source')
  if (data && channelSource) {
    data.loginSource = channelSource
  }
  const qhclickid = getCookie('qhclickid')
  if(data && channelSource && qhclickid){
    data.loginSource = `${channelSource}_${qhclickid}`
  }
  
  const bdVid = getCookie('bd_vid')
  if (data && bdVid) {
    channelSource || (channelSource = 'baidu')
    data.loginSource = `${channelSource}_${bdVid}`
  }
  return request({
    url: '/waic/account/console/login',
    data,
  })
}
