import { message } from 'antd';
import proxy from 'config/proxy';
import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { getCookie } from './cookie';
import { reportErrLogsByImg } from './util';
import {logOut} from './logOut'
import ModalDialog,{OpenModalDialog} from '../components/ModalDialog'
import openPayDialog from '../components/PayDialog'

interface Request extends AxiosRequestConfig {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH';
  data?: any;
  intercept?: boolean;
}
interface Response<T> {
  errcode?: string;
  errmsg?: string;
  data?: T;
}

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 30000,
});

instance.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
);



const request = async <ResData>({ method = 'POST', url, data, intercept = true, ...rest }: Request): Promise<Response<ResData>> => {
  const token = getToken();
  if (!token) {
    // localNavigate('/login');
  }
  if(url==='/waic/core/mastergo/import/codeWave'){
    instance.defaults.timeout = 180000;
  }else{
    instance.defaults.timeout = 30000;
  }

  const params = {
    ...rest,
    url,
    method,
    params: method === 'GET' ? data : null,
    data: method !== 'GET' ? data || {} : null
  };
  const {responseType}={...rest}
  const newParams = _.pickBy(params, _.identity);

  try {
    const res = await instance(newParams);
    if( responseType && responseType==='blob'){
      if(res){
        return res || {};
      }
    }

    if (res?.data?.errcode !== '0' && intercept) {
      switch(String(res?.data?.errcode)){
         case '45070002000003022':
         case '45070002000003003':
         case '45070002000001005':
          break
         case '45070002000005006':
            openPayDialog({type: 1, showPrompt: true,})
          // OpenModalDialog(1)
          break
         case '45070002000005007':
            openPayDialog({type: 2, showPrompt: true,})
          // OpenModalDialog(0)
          break
         case '45070002000003001':
         case '45070002000003011':
          logOut()
          break
         case '45070002000005016':
            ModalDialog.confirm({
              title: '该风格无法使用',
              content: '很遗憾，当前所选风格为会员专享，请升级会员后使用',
              cancelText: '取消',
              okText: '购买会员',
              onOk: () => {
                openPayDialog({})
              },
           });
          break
         default:
            message.error(res?.data?.errmsg);
      }
      reportErrLogsByImg({
        url,
        params: JSON.stringify(method === 'GET' ? params.params : params.data),
        header: '',
        errorCode: String(res?.data?.errcode),
        errorMsg: res?.data?.errmsg,
        globalTicket: res?.data?.globalTicket,
      });

      return Promise.reject(res.data);
    }
    
    if (res?.data?.errcode !== '0' && !intercept) {
        return Promise.reject(res.data);
    }

    return res?.data || {};
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getToken = (): string | undefined => {
  return getCookie('token');
};

export const setHeaders = (): void => {
  const token = getCookie('token');
  if (token) {
    instance.defaults.headers.common['Authorization'] = token;
  }
  instance.defaults.headers.common['Client-Type'] = 1;
};


export default request;
