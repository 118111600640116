import { proxy } from 'umi'
import { v4 as uuid } from 'uuid'
import { message } from 'antd'

import { SW_FILE_NAME } from '@/constants'
import { logoutReload } from '../utils/logOut'

import SharedWorker from '@okikio/sharedworker'

const worker = new SharedWorker(SW_FILE_NAME, { credentials: 'include' })
const client_id = uuid()

export enum WsTaskType {
  init = 1,
  close = 2,
  logout = 3,
  connected = 10000,
  // 任务进度
  taskProgress = 10001,
  // 画图任务完成
  drawTaskComplete = 10002,
  // 文案生成任务完成
  textTaskComplete = 10003,
  // 自动抠图完成
  autoCutoutComplete = 230000,
  // 图片主体分析完成
  imageCaptionComplete = 230001,
  // 分割抠图完成
  segmentTaskComplete = 230002,
  // 高清任务完成
  qualityTaskComplete = 230003,
  // 扩图任务完成
  expandTaskComplete = 230004,
  // 渲染背景完成
  backgroundTaskComplete = 230005,
  // 商详立即下载完成
  detailsTaskComplete = 230006,
  // 海报生成
  posterTaskComplete = 230007,
  // 批量渲染背景完成
  batchBackgroundTask = 230008,
  //创意海报生成
  creativeTaskComplete=230010,
  // 创意海报生成视觉元素
  cretiveVisualElementTaskComplete = 230009
}

interface WSData {
  data: any
  id: string
  time: number
  type: WsTaskType
  extend: {
    client_id?: string
    [key: string]: any
  }
}

type WebSocketState = typeof WebSocket.CONNECTING | typeof WebSocket.OPEN | typeof WebSocket.CLOSING | typeof WebSocket.CLOSED

export enum wsState {
  connecting = 0,
  open = 1,
  closing = 2,
  closed = 3,
}

// 创建全局状态
export const wsStore = proxy({
  webSocketState: WebSocket.CONNECTING as WebSocketState,
  broadcastMessage: null as WSData | null,
  sendMsg: (data: string, type = 1000, extend = {}) => {
    if (wsStore.webSocketState === WebSocket.CONNECTING) {
      // console.log('waiting for connection to be established.');
      message.warning('正在尝试重连，请稍后')
    } else if (wsStore.webSocketState === WebSocket.CLOSING || wsStore.webSocketState === WebSocket.CLOSED) {
      console.error('connection closed.')
      message.error('连接已断开')
    } else {
      worker.port.postMessage({
        // Include the sender information as a uuid to get back the response
        from: client_id,
        data: {
          id: uuid(),
          type,
          data,
          time: new Date().getTime(),
          extend: {
            client_id,
            ...extend,
          },
        },
      })
    }
  },
  init: (token: string) => {
    try {
      const { UMI_ENV } = process.env
      const originUrl =
        UMI_ENV === 'dev' || UMI_ENV === 'qa'
          ? 'ws://wai-c-coordinator.app.qa.internal.weimob.com/waic/coordinator/pc/websocket'
          : 'wss://api.wime-ai.com/waic/coordinator/pc/websocket'
      const wsURL = `${originUrl}?token=${token}`
      console.log('store>>>>>>>init??', worker)

      worker.port.postMessage({
        from: client_id,
        data: {
          id: uuid(),
          type: WsTaskType.init,
          data: wsURL,
          time: new Date().getTime(),
          extend: {
            client_id,
          },
        },
      })
    } catch (error) {
      console.log('🚀 ~初始化 error:', error)
    }
  },
  close: () => {
    worker.port.postMessage({
      from: client_id,
      data: {
        id: uuid(),
        type: WsTaskType.close,
        data: true,
        time: new Date().getTime(),
        extend: {
          client_id,
        },
      },
    })
  },
  onPortLogout: () => {
    worker.port.postMessage({
      from: client_id,
      data: {
        id: uuid(),
        type: WsTaskType.logout,
        data: '',
        time: new Date().getTime(),
        extend: {
          client_id,
        },
      },
    })
  },
})

try{
  console.log(`Initializing the web worker for client: ${client_id}`);
  // worker.port?.close?.()
  worker.port?.start?.()
}catch(err){
  console.log('start__err',err)

}

worker.port.onmessage = event => {
  console.log('workeronmessage======>',event)
  switch (event.data.type) {
    case 'WSState':
      console.log('🚀 ~ port ws state:', event.data.state)
      wsStore.webSocketState = event.data.state
      break
    case 'message':
      wsStore.broadcastMessage = event.data //这里其实是点播，要不要区分下？
      break
  }
}

const broadcastChannel = new BroadcastChannel('WebSocketChannel')
broadcastChannel.addEventListener('message', event => {
  // console.log('broadcastChannelMessage======>',event)

  switch (event.data.type) {
    case 'WSState':
      wsStore.webSocketState = event.data.state
      break
    case 'logout':
      //退出登录
      // console.log('logoutlogout')
      // if(getCookie('token')){
      logoutReload()
      // }
      break
      case 'info':
        console.log('info=======>event.data',event.data)
        break
    default:
      wsStore.broadcastMessage = event.data
      break
  }
})

export default wsStore
