export const APP_NAME = 'WAI-C'

export const FORMAT_TIME = 'YYYY-MM-DD HH:mm:ss'

export const FORMAT_DATE = 'YYYY-MM-DD'

export const SW_FILE_NAME = '/worker.js'

export const FULL_WIDTH = { width: '100%' }

export const LOGIN_QR_CODE_BASE_URL = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='

export const LOADING_GIF = 'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/101/weimengloading.gif'

export const CUSTOMER_SERVICE_QR_CODE =
  'https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/wecom-temp-4755-283a11e8e9ea813340c5bf31b84b195e.png'

export enum SceneType {
  '抠图' = 1001,
  '商品主图' = 1002,
  '商详图' = 1003,
  '营销海报' = 1004,
  '海报一键生成' = 1015,
  '海报画相似' = 1016,
  '营销海报下载' = 1018,
  '商详重绘' = 1005,
  // 收费 - 抠图
  '智能抠图' = 1006,
  '图片分割抠图' = 1007,
  // 收费- 小工具换背景
  '智能抠图换背景' = 1008,
  '智能高清' = 1009,
  '智能超清' = 1011,
  '智能扩图' = 1010,
  '渲染背景' = 1012,
  '商详下载' = 1013,
  '商详预生成' = 1014,
  '批量生成背景'= 1024,
  '创意海报'=1025,
  '创意海报下载'=1028,
  '小红书种草文案' = 2001,
  '朋友圈营销文案' = 2002,
  '社群推广文案' = 2003,
  '公众号推广文案' = 2004,
  '商品标题主体解析' = 2005,
  '绘图提示词扩写' = 2006,
  '文案润色' = 2007,
  'ai帮我写' = 2008,
  '一键生成商详' = 2009,
  '新增或更换模块' = 2010
 
}

export enum SizeType {
  '手机海报' = 1,
  '横版海报' = 2,
  '公众号首图' = 3,
  '公众号次图' = 4,
  '小红书配图' = 5,
  '文章长图' = 6,
  '电商横版海报' = 7,
  '小程序启动页' = 8,
  '自定义' = 9,
}

export enum newSizeType{
  '手机海报'=1,
  '横版海报' = 2,
  '文章长图' = 3,
  '电商横版海报'=4,
  '小程序启动页'=5,
  '电商主图'=6,
  '自定义' = 7,
}

export enum MemberType {
  未开通,
  已开通,
  已过期,
}
export const continueType: any = [
  { id: SceneType.商品主图, name: 'AI商拍图' },
  { id: SceneType.营销海报, name: 'AI商品海报' },
  { id: SceneType.商详图, name: 'AI商详页' },
  { id: SceneType.智能抠图, name: '智能抠图' },
  { id: SceneType.智能抠图换背景, name: '智能换背景' },
  { id: SceneType.智能扩图, name: '智能扩图' },
  { id: SceneType.智能高清, name: '智能高清' },
  { id: SceneType.智能超清, name: '智能超清' },
]
export const sceneUrl: any = {
  1002: '/create/product-main-image',
  1003: '/create/product-details',
  1004: '/create/marketing-poster',
  1015: '/create/marketing-poster',
  1016: '/create/marketing-poster',
  1005: '/create/product-details',
  1012: '/create/product-details',
  1013: '/create/product-details',
  1014: '/create/product-details',
  1025: '/create/creativePoster',
  2001: '/create/marketing-writing',
  2002: '/create/marketing-writing',
  2003: '/create/marketing-writing',
  2004: '/create/marketing-writing',
  2007: '/create/marketing-writing',
  1006: '/tools/image-cutout',
  1010: '/tools/image-expand',
  1009: '/tools/image-quality',
  1011: '/tools/image-quality',
  1008: '/tools/background-replacement',
}
export const vistorQueryParams=['isVistorQuery']